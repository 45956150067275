import React from 'react';
import {styled} from "@mui/material/styles";
import cn from "classnames";

interface InfoPaperProps {
    children: React.ReactNode,
    mode?: 'transparent' | 'black',
};

const InfoPaperStyled = styled('div')(({theme}) => ({
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: '9px',
    height: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        '&.transparent': {
            backgroundColor: 'transparent',
        }
    }
}));

export const InfoPaper = (props: InfoPaperProps) => {
    const {
        children,
        mode = 'black'
    } = props;

    return(
        <InfoPaperStyled className={cn({
            'transparent': mode === 'transparent'
        })}>
            { children }
        </InfoPaperStyled>
    );
}

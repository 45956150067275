import React, {useContext, useEffect, useMemo, useState} from 'react';
import cn from "classnames";
import {Container, Icon, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import {CloseButton} from "../CloseButton";
import ModalBg from "../../assets/images/backgrounds/realms-modal-bg.png";
import Grid from "@mui/material/Grid";
import {CardBox} from "../CardBox";
import {GiftCard} from "../GiftCard";
import StyledRoundedButton from "../StyledRoundedButton";
import {ModalContainer} from "./ModalContainer";
import {styled, useTheme} from "@mui/material/styles";
import {I_GiftCard} from "../../types/giftCard";
import {InfoPaper} from "../Paper";
import HandIcon from "../../assets/images/icons/click_tap_single_hand_gesture_icon.svg";
import EyeOpenIcon from "../../assets/images/icons/eye-open.svg";
import EyeClosedIcon from "../../assets/images/icons/eye-closed.svg";
import {BodyGraphContext, CardItemsContext} from "../../App";
import {appConfig} from "../../config/appConfig";
import {CardDescriptionTabs} from "../CardDescriptionTabs";

interface RealmsModalProps {
    open: boolean;
    onClose: () => void;
    text: string[];
    name: string;
    realmImage: string;
    number: number;
}

const StyledMobileCardSwitcher = styled('button')(({theme}) => ({
    background: 'transparent',
    color: theme.palette.primary.main,
    width: '75px',
    outline: 'none',
    border: 'none',
    borderTop: `2px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: theme.spacing(1),
    cursor: 'pointer',
    opacity: .35,
    '&.active': {
        opacity: 1
    },
    '&.no-border': {
        borderTop: 'none',
    }
}));

export const RealmsModal = (props: RealmsModalProps) => {
    const {
        open,
        onClose,
        text,
        name,
        realmImage,
        number,
    } = props;


    const {data: BodyGraph} = useContext(BodyGraphContext);
    const cardsData = useContext(CardItemsContext);

    const cards = useMemo(() => {
        if (!BodyGraph || number === 0) return [] as Array<I_GiftCard>;
        if (!cardsData) return [] as Array<I_GiftCard>;

        const {Design, Personality} = BodyGraph;

        const cardsGraph = {
            "1": [{
                id: Personality.Sun.Gate,
                card: cardsData[Personality.Sun.Gate - 1]
            }, {
                id: Design.Sun.Gate,
                card: cardsData[Design.Sun.Gate - 1]
            }] as I_GiftCard[],
            "2": [{
                id: Personality.Earth.Gate,
                card: cardsData[Personality.Earth.Gate - 1]
            }, {
                id: Design.Earth.Gate,
                card: cardsData[Design.Earth.Gate - 1]
            }] as I_GiftCard[],
            "3": [{
                id: Personality.Mercury.Gate,
                card: cardsData[Personality.Mercury.Gate - 1]
            }, {
                id: Design.Mercury.Gate,
                card: cardsData[Design.Mercury.Gate - 1]
            }] as I_GiftCard[],
            "4": [{
                id: Personality["South Node"].Gate,
                card: cardsData[Personality["South Node"].Gate - 1]
            }, {
                id: Design["South Node"].Gate,
                card: cardsData[Design["South Node"].Gate - 1]
            }] as I_GiftCard[],
            "5": [{
                id: Personality["Moon"].Gate,
                card: cardsData[Personality["Moon"].Gate - 1]
            }, {
                id: Design["Moon"].Gate,
                card: cardsData[Design["Moon"].Gate - 1]
            }] as I_GiftCard[],
            "6": [{
                id: Personality["Saturn"].Gate,
                card: cardsData[Personality["Saturn"].Gate - 1]
            }, {
                id: Design["Saturn"].Gate,
                card: cardsData[Design["Saturn"].Gate - 1]
            }] as I_GiftCard[],
            "7": [{
                id: Personality["Venus"].Gate,
                card: cardsData[Personality["Venus"].Gate - 1]
            }, {
                id: Design["Venus"].Gate,
                card: cardsData[Design["Venus"].Gate - 1]
            }] as I_GiftCard[],
            "8": [{
                id: Personality["Pluto"].Gate,
                card: cardsData[Personality["Pluto"].Gate - 1]
            }, {
                id: Design["Pluto"].Gate,
                card: cardsData[Design["Pluto"].Gate - 1]
            }] as I_GiftCard[],
            "9": [{
                id: Personality["Jupiter"].Gate,
                card: cardsData[Personality["Jupiter"].Gate - 1]
            }, {
                id: Design["Jupiter"].Gate,
                card: cardsData[Design["Jupiter"].Gate - 1]
            }] as I_GiftCard[],
            "10": [{
                id: Personality["Mars"].Gate,
                card: cardsData[Personality["Mars"].Gate - 1]
            }, {
                id: Design["Mars"].Gate,
                card: cardsData[Design["Mars"].Gate - 1]
            }] as I_GiftCard[],
            "11": [{
                id: Personality["Uranus"].Gate,
                card: cardsData[Personality["Uranus"].Gate - 1]
            }, {
                id: Design["Uranus"].Gate,
                card: cardsData[Design["Uranus"].Gate - 1]
            }] as I_GiftCard[],
            "12": [{
                id: Personality["Neptune"].Gate,
                card: cardsData[Personality["Neptune"].Gate - 1]
            }, {
                id: Design["Neptune"].Gate,
                card: cardsData[Design["Neptune"].Gate - 1]
            }] as I_GiftCard[],
        } as Record<string, I_GiftCard[]>;

        return cardsGraph[number] || [] as Array<I_GiftCard>;
    }, [BodyGraph, number]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [cardSelected, setCardSelected] = useState<number>(cards[0]?.id || 0);
    const [submodalOpen, setSubmodalOpen] = useState<boolean>(false);

    useEffect(() => {
        setCardSelected(cards[0]?.id)
    }, [cards]);


    const handleOpenSubmodal = () => {
        setSubmodalOpen(true);
    }

    const handleCloseSubmodal = () => {
        setSubmodalOpen(false);
    }

    const handleClickOnCardSwitcher = (cardId: number) => {
        setCardSelected(cardId);

        handleOpenSubmodal();
    }

    const handleClickOnGiftCard = (cardId: number) => {
        setCardSelected(cardId);

        if (cardSelected === cardId) {
            handleOpenSubmodal();
        }
    }

    const DescriptionComponent = (
        <Box sx={{
            opacity: .8
        }}>
            {text.map(paragraph =>
                <Typography variant="body2" pb={2}>
                    {paragraph}
                </Typography>
            )}
        </Box>
    );

    const redirectToExternalResource = () => {
        window.location.href = appConfig.externalResourceLink;
    }

    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container sx={{
                outline: 'none',
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }}>
                <Box style={{
                    borderTop: '1px solid #666',
                    background: isMobile ? 'transparent' : '#333',
                    position: 'relative',
                    maxWidth: '100%'
                }}>
                    <Box mb={2} sx={{
                        position: 'fixed',
                        zIndex: 100,
                        right: isMobile ? 20 : 30,
                        top: isMobile ? 20 : 30,
                    }}>
                        <CloseButton onClick={onClose} size={isMobile ? 24 : 40}/>
                    </Box>
                    <Box style={{backgroundImage: isMobile ? undefined : `url(${ModalBg})`}} px={{xs: 0, md: 7}}
                         pb={{xs: 0, md: 7}}
                         pt={{xs: 0, md: 12}}
                         sx={{
                             backgroundSize: 'cover',
                             overflowY: 'auto',
                             overflowX: 'hidden',
                             maxHeight: '100vh'
                         }}>

                        <Box pb={{xs: 3, md: 6}} sx={{
                            borderBottom: {
                                xs: 'none',
                                md: '1px solid #fff'
                            }
                        }}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} md={6} lg={5} style={{textAlign: 'center'}}>
                                    <Box mt={{xs: 8, md: 0}} mb={{xs: 4, md: 0}} sx={{position: 'relative'}}>
                                        <img src={realmImage} alt="" style={{
                                            maxWidth: isMobile ? '50%' : undefined
                                        }}/>
                                        <Box sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            margin: 'auto',
                                            height: 'max-content',
                                        }}>
                                            <Typography fontSize={24} fontWeight={400} sx={{opacity: .7}}>
                                                Realm {number}
                                            </Typography>
                                            <Typography fontFamily="BelledeMai" sx={{
                                                fontSize: {
                                                    xs: 48,
                                                    md: '4vw'
                                                },
                                                textTransform: "uppercase"
                                            }}>
                                                {name}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6} lg={6}>
                                    {isMobile ?
                                        <InfoPaper>
                                            {DescriptionComponent}
                                        </InfoPaper>
                                        :
                                        DescriptionComponent
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pt={{xs: 3, md: 6}}>
                            {isMobile &&
                                <Box component="div" mb={4}>
                                    <Grid container alignItems="start">
                                        <Grid xs="auto">
                                            <Box
                                                sx={{
                                                    width: 50
                                                }}
                                            >
                                                <img src={HandIcon} alt=""/>
                                            </Box>
                                        </Grid>
                                        <Grid xs>
                                            <Typography variant="body2">
                                                Click each section to
                                                learn about the realm
                                                and the 2 relevant gifts/shadows
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                            <Grid container flexWrap={{xs: "wrap", md: "nowrap"}}>
                                <Grid item xs={12} md={6} sx={{flexShrink: 0}}>
                                    <Box display="flex" alignItems="center">
                                        <Box display={{xs: 'none', md: 'block'}}>
                                            <StyledMobileCardSwitcher
                                                className={cn("active", "no-border")}
                                                onClick={() => handleClickOnCardSwitcher(cards[0].id)}
                                            >
                                                <Icon>
                                                    <img src={EyeOpenIcon} alt=""/>
                                                </Icon>
                                                <Typography variant="body2">
                                                    Your conscious theme
                                                </Typography>
                                            </StyledMobileCardSwitcher>
                                        </Box>
                                        <Box sx={{
                                            maxWidth: '400px',
                                            position: 'relative',
                                            margin: {xs: 'auto', md: 0},
                                            width: {xs: '100%', md: '225px'},
                                        }}>
                                            <CardBox active>
                                                {cards.map((item, index) =>
                                                    <GiftCard
                                                        mode={!!cardSelected ? 'selected' : 'unselected'}
                                                        selected={cardSelected === item.id}
                                                        key={item.id}
                                                        bgImage={item.card.background}
                                                        onClick={() => handleClickOnGiftCard(item.id)}
                                                        index={cardSelected}
                                                        cardIndex={index}
                                                        small
                                                    />
                                                )}
                                            </CardBox>
                                        </Box>
                                        <Box display={{xs: 'none', md: 'block'}}>
                                            <StyledMobileCardSwitcher
                                                className={cn("active", "no-border")}
                                                onClick={() => handleClickOnCardSwitcher(cards[1].id)}
                                            >
                                                <Icon>
                                                    <img src={EyeClosedIcon} alt=""/>
                                                </Icon>
                                                <Typography variant="body2">
                                                    Your unconscious theme
                                                </Typography>
                                            </StyledMobileCardSwitcher>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={12} display={{xs: 'block', md: 'none'}}>

                                    {/*Mobile*/}
                                    <Box display="flex" justifyContent="center" mt={3}>
                                        <StyledMobileCardSwitcher
                                            className={cn({
                                                "active": cardSelected === cards[0]?.id
                                            })}
                                            onClick={() => handleClickOnCardSwitcher(cards[0].id)}
                                        >
                                            <Icon>
                                                <img src={EyeOpenIcon} alt=""/>
                                            </Icon>
                                            <Typography variant="body2">
                                                Your conscious theme
                                            </Typography>
                                        </StyledMobileCardSwitcher>
                                        <StyledMobileCardSwitcher
                                            className={cn({
                                                "active": cardSelected === cards[1]?.id
                                            })}
                                            onClick={() => handleClickOnCardSwitcher(cards[1].id)}
                                        >
                                            <Icon>
                                                <img src={EyeClosedIcon} alt=""/>
                                            </Icon>
                                            <Typography variant="body2">
                                                Your unconscious theme
                                            </Typography>
                                        </StyledMobileCardSwitcher>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md="auto" display={{xs: 'none', md: 'block'}}
                                      sx={{flexShrink: {md: 1}}}>
                                    <Typography variant="h5" fontWeight={400}>
                                        Interested in integrating your Shadows
                                        and harnessing your Gifts?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md="auto">
                                    <Box display="flex" flexDirection="column" alignItems="center"
                                         mt={{xs: 4, md: 0}} mb={{xs: 8, md: 0}}
                                         pt={{xs: 4, md: 0}}
                                         sx={{
                                             borderTop: {
                                                 xs: '1px solid #fff',
                                                 md: 'none'
                                             }
                                         }}>
                                        <StyledRoundedButton onClick={redirectToExternalResource} text="discover"/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <ModalContainer
                    open={submodalOpen}
                >
                    <Grid container justifyContent="center" alignItems="center" sx={{
                        outline: 'none',
                    }}>
                        <Grid item xs={12} md={6} lg={5}>
                            <Box sx={{
                                backgroundColor: 'rgba(0,0,0,.9)'
                            }}>
                                <Box style={{backgroundImage: `url(${ModalBg})`}}
                                     px={{xs: 3, md: 7}}
                                     pb={{xs: 3.5, md: 7}}
                                     pt={{xs: 3.5, md: 7}}
                                     sx={{
                                         backgroundSize: 'cover',
                                         overflowY: 'auto',
                                         overflowX: 'hidden',
                                         maxHeight: '100vh',
                                         position: 'relative',
                                         margin: 'auto'
                                     }}>
                                    <Box mb={2} sx={{
                                        position: 'absolute',
                                        right: isMobile ? 20 : 30,
                                        top: isMobile ? 20 : 30,
                                    }}>
                                        <CloseButton onClick={handleCloseSubmodal} size={isMobile ? 24 : 40}/>
                                    </Box>
                                    {cardsData && !!cards.length && cardSelected &&
                                        <CardDescriptionTabs card={cards.filter(item => item.id === cardSelected)[0].card}/>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                </ModalContainer>
            </Container>
        </ModalContainer>
    );
}

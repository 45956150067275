import React from 'react';
import {Button} from "@mui/material";
import closeIcon from "../assets/images/icons/close.png";
import {styled} from "@mui/material/styles";

interface CloseButtonProps {
    onClick: () => void;
    size?: number;
};

const CloseButtonStyled = styled(Button)({
    background: 'transparent',
    padding: 0,
    minWidth: 0,
    opacity: .8,
    zIndex: 8000,
    '&:hover': {
        background: 'transparent',
        opacity: 1
    }
});

export const CloseButton = (props: CloseButtonProps) => {
    const {
        onClick,
        size = 24
    } = props;

    return (
        <CloseButtonStyled onClick={onClick} aria-label="close">
            <img
                src={closeIcon}
                alt="close"
                style={{
                    width: size,
                    height: size
                }}
            />
        </CloseButtonStyled>
    );
}

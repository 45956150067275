import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import cn from 'classnames';
import {useNavigate} from "react-router-dom";
import EllipseIcon from '../assets/images/icons/Ellipse.svg';
import Subtract from '../assets/images/icons/Subtract.svg';
import Grid from '@mui/material/Grid';
import PageContainer from "../components/PageContainer";
import {Container, Fade, Typography, useMediaQuery} from "@mui/material";
import {GiftCard} from "../components/GiftCard";
import {styled, useTheme} from "@mui/material/styles";
import {Navigation} from "../components/Navigation";
import Box from "@mui/material/Box";
import {RoutesMap} from "../utils/RoutesMap";
import {CardDescriptionTabs} from "../components/CardDescriptionTabs";
import {I_GiftCard} from "../types/giftCard";
import {InfoPaper} from "../components/Paper";
import HandIcon from "../assets/images/icons/click_tap_single_hand_gesture_icon.svg";
import {CloseButton} from "../components/CloseButton";
import {ModalContainer} from "../components/Modals/ModalContainer";
import {CardBox} from "../components/CardBox";
import {BodyGraphContext, CardItemsContext} from "../App";
import {appConfig} from "../config/appConfig";
import axios from "axios";
import {SimplyDictionaryItem} from "../types/dictionaries";


const CardSwitchItem = styled('button')(({theme}) => ({
    boxSizing: 'content-box',
    width: '46px',
    height: '2px',
    opacity: '.2',
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'relative',
    '&::before': {
        content: "''",
        width: '46px',
        height: '2px',
        left: 0,
        right: 0,
        margin: 'auto',
        backgroundColor: theme.palette.secondary.main,
        position: 'absolute',
    },
    '&.active': {
        opacity: '1'
    },

}));


const ShadowIcon = styled('img')(({theme}) => ({
    marginBottom: theme.spacing(-0.25)
}));


const CardsPage = (): JSX.Element => {

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {data: BodyGraph} = useContext(BodyGraphContext);
    const cardsData = useContext(CardItemsContext);

    const cards = useMemo((): Array<I_GiftCard> => {
        if (!BodyGraph) return [] as Array<I_GiftCard>;
        if (!cardsData) return [] as Array<I_GiftCard>;
        const {Design, Personality} = BodyGraph;

        return [{
            id: Design.Sun.Gate,
            index: 1,
            card: cardsData[Design.Sun.Gate - 1]
        }, {
            id: Design.Earth.Gate,
            index: 2,
            card: cardsData[Design.Earth.Gate - 1]
        }, {
            id: Personality.Sun.Gate,
            index: 3,
            card: cardsData[Personality.Sun.Gate - 1]
        }, {
            id: Personality.Earth.Gate,
            index: 4,
            card: cardsData[Personality.Earth.Gate - 1]
        }] as Array<I_GiftCard>

    }, [BodyGraph, cardsData]);

    const [pageDictionary, setPageDictionary] = useState<string[] | null>(null);
    const [cardSelected, setCardSelected] = useState<number>(0);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (cards.length) {
            if (isMobile) {
                setCardSelected(cards[0].id)
            }
        }
    }, [isMobile, cards]);

    useEffect(() => {
        axios({
            method: 'get',
            url: '/cards-page',
            baseURL: appConfig.apiUrl
        })
            .then(response => {
                setPageDictionary(response.data.map((item: SimplyDictionaryItem) => item.text))
            })
    }, []);


    useEffect(() => {
        if (!cards || !Array.isArray(cards) || !cards.length) return;

        let touchstartX = 0;
        let touchendX = 0;

        const checkDirection = (): 'top' | 'bottom' | 'none' => {
            if (modalOpen) return 'none';
            if (touchendX + 80 < touchstartX) return 'top';
            if (touchendX > touchstartX + 80) return 'bottom';
            return 'none';
        }

        const touchStartCallback = (e: TouchEvent) => {
            touchstartX = e.changedTouches[0].screenX;
        }

        const touchEndCallback = (e: TouchEvent) => {
            touchendX = e.changedTouches[0].screenX;
            const direction = checkDirection();
            const currentCard = cards.findIndex(card => card.id === cardSelected);
            let nextCard;

            if (direction === "top") {
                nextCard = currentCard === cards.length - 1
                    ? cards[0].id : cards[currentCard + 1].id;
                setCardSelected(nextCard);
            } else if (direction === "bottom") {
                nextCard = currentCard === 0
                    ? cards[cards.length - 1].id : cards[currentCard - 1].id;
                setCardSelected(nextCard);
            }
        }

        document.addEventListener('touchstart', touchStartCallback);
        document.addEventListener('touchend', touchEndCallback);

        return () => {
            document.removeEventListener('touchstart', touchStartCallback);
            document.removeEventListener('touchend', touchEndCallback);
        }
    }, [cardSelected, modalOpen]);

    const goNext = useCallback(() => {
        navigate(RoutesMap.Realms);
    }, []);

    const handleClose = () => {
        setModalOpen(false);
    }

    const handleOpen = () => {
        setModalOpen(true);
    }

    const handleClickOnGiftCard = (cardId: number) => {
        if (isMobile) {
            if (cardId === cardSelected) {
                handleOpen();
            }
            setCardSelected(cardId);
        } else {
            setCardSelected(cardId);
        }
    }


    const InfoBlock = () => {
        return (
            <Grid xs={6}>
                <Typography variant={isMobile ? 'body2' : 'h5'} textAlign="center" mb={{
                    xs: 2,
                    md: 5
                }}>
                    {pageDictionary && pageDictionary[0]}
                </Typography>
            </Grid>
        );
    }

    const CardSwitch = () => {
        return (
            <Box mt={1} sx={{
                width: {
                    xs: '100%',
                    md: 250
                },
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                right: '18px',
                left: {
                    xs: 0,
                    md: 'unset'
                },
                bottom: '-30px'
            }}>
                {cards.map((item) =>
                    <CardSwitchItem
                        key={item.id}
                        onClick={() => {
                            setCardSelected(item.id);
                        }}
                        className={cn({
                            'active': item.id === cardSelected
                        })}
                    />
                )}
            </Box>
        );
    }

    return (
        <PageContainer verticalAlign={isMobile ? 'start' : 'center'}>
            <Box component="div" my={2} sx={{
                maxWidth: 1600,
                width: '100%'
            }}>
                <Fade in timeout={appConfig.fadeAnimationDuration}>
                    <Grid container alignItems="start" justifyContent="center" sx={{
                        position: {
                            sm: 'static',
                            xl: window.innerHeight > 850 ? 'absolute' : 'static',
                        },
                        top: 24,
                        left: 0
                    }}>
                        <Box mb={{
                            xs: 1,
                            md: 4
                        }}>
                            <Navigation
                                onClickNext={goNext}
                                showNext={!!cardSelected}
                            />
                        </Box>
                    </Grid>
                </Fade>

                {(!cardSelected || isMobile) &&
                    <Fade in timeout={appConfig.fadeAnimationDuration}>
                        <Box component="div" mx={{
                            xs: 3,
                            md: 7
                        }}>
                            <InfoBlock/>
                        </Box>
                    </Fade>
                }

                {isMobile &&
                    <Box component="div" mx={{
                        xs: 3,
                        md: 7
                    }} mb={2}>
                        <Grid container alignItems="start">
                            <Grid xs="auto">
                                <Box
                                    sx={{
                                        width: 50
                                    }}
                                >
                                    <img src={HandIcon} alt=""/>
                                </Box>
                            </Grid>
                            <Grid xs>
                                <Typography variant="body2">
                                    Click each section to
                                    learn about the realm
                                    and the 2 relevant gifts/shadows
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <Container maxWidth={!!cardSelected ? false : 'lg'} fixed disableGutters>
                    <Box display="flex" mb={!!cardSelected ? 0 : 2} mx={{
                        xs: 3,
                        md: 0,
                        lg: cardSelected ? 5 : 7
                    }}>

                        <Box sx={{
                            maxWidth: '100%',
                            position: 'relative',
                            width: {
                                xs: '100%',
                                md: !!cardSelected ? '50%' : '100%',
                                lg: !!cardSelected ? 'calc(50% + 145px)' : '100%',
                            }
                        }}>
                            <CardBox active={!!cardSelected}>
                                {cards.map((item, index) =>
                                    <GiftCard
                                        mode={!!cardSelected ? 'selected' : 'unselected'}
                                        selected={cardSelected === item.id}
                                        key={item.id}
                                        bgImage={item.card.background}
                                        onClick={() => handleClickOnGiftCard(item.id)}
                                        index={cards.find(value => value.id === cardSelected)?.index || index}
                                        cardIndex={index}
                                    />
                                )}
                            </CardBox>

                            {!!cardSelected &&
                                <CardSwitch/>
                            }
                        </Box>
                        {!!cardSelected &&
                            <Box sx={{
                                flex: 1,
                                display: {
                                    xs: 'none',
                                    md: 'block'
                                }
                            }}>
                                <Box ml={5}>
                                    {cardsData &&
                                        <InfoPaper>
                                            <CardDescriptionTabs card={cards.filter(item => item.id === cardSelected)[0]?.card}/>
                                        </InfoPaper>
                                    }
                                </Box>
                            </Box>
                        }
                    </Box>
                </Container>


                <Box mx={{
                    xs: 3,
                    md: 7
                }}>
                    <Fade in timeout={appConfig.fadeAnimationDuration}>
                        <Grid container alignItems="start" justifyContent="center">
                            <Grid item xs={12} md={4}>
                                <Typography variant="body2" textAlign="center" mt={5}>
                                    Each theme of consciousness unveils
                                </Typography>
                                <Typography variant="body2" textAlign="center" mb={2}>
                                    a Gift
                                    <Box mx={0.5} component="span">
                                        <img src={EllipseIcon} alt=""/>
                                    </Box>
                                    (light or beneficent quality) and a Shadow
                                    <Box mx={0.5} component="span">
                                        <ShadowIcon src={Subtract} alt=""/>
                                    </Box>
                                    (dark or challenging quality).
                                </Typography>
                                <Typography variant="body2" textAlign="center">
                                    {pageDictionary && pageDictionary[2]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fade>
                </Box>

            </Box>
            <ModalContainer
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid container justifyContent="center" sx={{
                    outline: 'none'
                }}>
                    <Grid item xs={12} sm={9} md={6} lg={5} style={{textAlign: 'center'}}>
                        <Box my={3} mx={3}>
                            <Grid container justifyContent="end">
                                <Grid xs="auto">
                                    <Box mb={2}>
                                        <CloseButton onClick={handleClose}/>
                                    </Box>
                                </Grid>
                            </Grid>
                            {cards &&
                                <InfoPaper>
                                    <CardDescriptionTabs card={cards.filter(item => item.id === cardSelected)[0]?.card}/>
                                </InfoPaper>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </ModalContainer>
        </PageContainer>
    )
}

export default CardsPage;

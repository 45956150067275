import React from 'react';
import {styled} from "@mui/material/styles";
import cn from "classnames";

interface CardBoxProps {
    children: React.ReactNode,
    active: boolean
};


const CardBoxStyled = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
    height: 'max-content',

    '&.active': {
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
        }
    },
    '&:not(.active)': {
        '&:hover': {
            '& div': {
                filter: 'brightness(35%)',
            }
        },
        '& div': {
            '&:hover': {
                filter: 'none',
                transform: 'scale(1.1)',
                zIndex: 100
            }
        }
    },

}));

export const CardBox = (props: CardBoxProps) => {
    const {
        children,
        active
    } = props;

    return(
        <CardBoxStyled className={cn({
            'active': active
        })}>
            { children }
        </CardBoxStyled>
    );
}

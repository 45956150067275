import React, { ReactNode, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FormControl, FormHelperText, Input, InputAdornment, useMediaQuery } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CityAutocomplete } from './Fields/CityAutocomplete';
import { HomepageFormData, SetFieldCallback } from '../../types/homepageForm';

interface HomepageFormProps {
  formData: HomepageFormData;
  setField: SetFieldCallback;
  setUserTimezone: (zone: string) => void;
}

const CssTextField = styled(FormControl)(({ theme }) => ({
  paddingRight: '2em',
  '& .MuiInput-root': {
    '&.Mui-error::after': {
      borderBottom: `1px solid ${theme.palette.error}`
    },
    '&::before': {
      borderBottom: '1px solid #D9D9D9'
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 0
    },
    '& .MuiIconButton-root': {
      padding: 0,
      paddingRight: theme.spacing(2)
    }
  },
  [theme.breakpoints.down('md')]: {
    paddingRight: '0'
  }
}));

const HomepageForm = (props: HomepageFormProps): JSX.Element => {
  const { formData, setField, setUserTimezone } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      component='form'
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' }
      }}
      noValidate
      autoComplete='off'>
      <Grid container justifyContent='space-between' direction='row' spacing={isMobile ? 2 : 5}>
        <Grid item xs={6}>
          <Box
            mb={{
              xs: 2,
              md: 0
            }}>
            <CssTextField variant='standard' fullWidth>
              <Input
                error={formData.name.error}
                type='text'
                value={formData.name.value}
                name='name'
                placeholder='Full Name'
                onChange={e => setField('name', e.target.value)}
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      opacity: 1
                    }
                  }
                }}
              />
              {!!formData.name.error && !!formData.name.errorText && (
                <FormHelperText error>{formData.name.errorText}</FormHelperText>
              )}
            </CssTextField>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            mb={{
              xs: 2,
              md: 0
            }}>
            <CssTextField variant='standard' fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={formData.birthDate.value}
                  onChange={(value: any) => setField('birthDate', value)}
                  components={{
                    OpenPickerIcon: () => <CalendarTodayOutlinedIcon color='primary' />
                  }}
                  renderInput={(params: any) => {
                    const { inputRef, InputProps } = params;

                    return (
                      <Input
                        {...params}
                        ref={inputRef}
                        error={formData.birthDate.error}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Birthdate'
                        }}
                        sx={{
                          input: {
                            '&::placeholder': {
                              opacity: 1
                            }
                          }
                        }}
                        startAdornment={
                          <InputAdornment position='start'>
                            {isMobile ? (
                              <CalendarTodayOutlinedIcon color='primary' />
                            ) : (
                              InputProps?.endAdornment
                            )}
                          </InputAdornment>
                        }
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </CssTextField>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            mb={{
              xs: 2,
              md: 0
            }}>
            <CssTextField variant='standard' fullWidth>
              <Input
                id='standard-adornment-password'
                type='email'
                value={formData.email.value}
                error={formData.email.error}
                placeholder='E-mail'
                onChange={e => setField('email', e.target.value)}
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      opacity: 1
                    }
                  }
                }}
              />
              {!!formData.email.error && !!formData.email.errorText && (
                <FormHelperText error>{formData.email.errorText}</FormHelperText>
              )}
            </CssTextField>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            mb={{
              xs: 2,
              md: 0
            }}>
            <CssTextField variant='standard' fullWidth>
              <CityAutocomplete
                value={formData.birthLocation.value}
                error={formData.birthLocation.error}
                setUserTimezone={setUserTimezone}
                onChange={value => setField('birthLocation', value)}
              />
            </CssTextField>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            mb={{
              xs: 2,
              md: 0
            }}>
            <CssTextField variant='standard' fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={formData.birthTime.value}
                  label='min/max time'
                  components={{
                    OpenPickerIcon: () => <AccessTimeOutlinedIcon color='primary' />
                  }}
                  onChange={(value: any) => setField('birthTime', value)}
                  renderInput={(params: any) => {
                    const { inputRef, InputProps } = params;
                    return (
                      <Input
                        {...params}
                        ref={inputRef}
                        error={formData.birthTime.error}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Time of Birth'
                        }}
                        sx={{
                          input: {
                            '&::placeholder': {
                              opacity: 1
                            }
                          }
                        }}
                        startAdornment={
                          <InputAdornment position='start'>
                            {isMobile ? (
                              <AccessTimeOutlinedIcon color='primary' />
                            ) : (
                              InputProps?.endAdornment
                            )}
                          </InputAdornment>
                        }
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </CssTextField>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomepageForm;

import { createTheme } from '@mui/material/styles';
import {grey, red} from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
    typography: {
        fontFamily: ['Inter', 'BelledeMai', 'sans-serif'].join(','),
        body2: {
            fontSize: '0.875rem',
            fontWeight: '300'
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: '400'
        }
    },
    palette: {
        primary: {
            main: '#FFFFFF',
            contrastText: "#000",
        },
        action: {
            active: '#FFFFFF',
            focus: '#FFFFFF',
            disabled: grey.A700
        },
        secondary: {
            main: '#D5D5D5',
        },
        background: {
            paper: '#000',
        },
        error: {
            main: red.A400,
        },
        text: {
            primary: '#FFF',
            secondary: '#FFF',
        },
    },
});

export default theme;

import React, {ReactNode} from "react";


const NotFoundPage = (): JSX.Element => {

    return (
        <div>
            NotFoundPage;
        </div>
    )
}

export default NotFoundPage;

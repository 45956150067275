import React from 'react';
import RoBStackedLogo from "../assets/images/logo/RoBOvalLogo.png";
import ArrowLeft from '../assets/images/icons/navarrow-left.svg';
import ArrowRight from '../assets/images/icons/navarrow_right.svg';
import {styled} from "@mui/material/styles";


interface NavigationProps {
    showBack?: boolean;
    showNext?: boolean;
    onClickBack?: () => void;
    onClickNext?: () => void;
};

const StyledNavigationOuter = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const StyledNavigationLogo = styled('img')({
    width: '64px',
    height: '76px'
});

const StyledButton = styled('button')(({theme}) => ({
    backgroundColor: 'transparent',
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    fontWeight: 300,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'transform .4s',
    '& img': {
        margin: theme.spacing(0, 1)
    },
    '& span': {
        opacity: .7,
    },
    '&.right': {
        marginLeft: '10px'
    },
    '&.left': {
        marginRight: '10px'
    },
    '&:hover.right': {
        transform: 'translate(15px)'
    },
    '&:hover.left': {
        transform: 'translate(-15px)'
    }
}));

export const Navigation = (props: NavigationProps) => {

    const {
        showBack = false,
        showNext = false,
        onClickBack,
        onClickNext
    } = props;

    return (
        <StyledNavigationOuter>
            <StyledButton onClick={onClickBack} className="left"
                          style={{visibility: showBack ? 'visible' : 'hidden'}}>
                <img src={ArrowLeft} alt=""/>
                <span>
                        Back
                    </span>
            </StyledButton>
            <StyledNavigationLogo src={RoBStackedLogo} alt=""/>
            <StyledButton onClick={onClickNext} className="right"
                          style={{visibility: showNext ? 'visible' : 'hidden'}}>
                    <span>
                        Next
                    </span>
                <img src={ArrowRight} alt=""/>
            </StyledButton>
        </StyledNavigationOuter>
    );
}

import React, {useCallback, useContext, useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Fade, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {styled, useTheme} from "@mui/material/styles";
import Ellipse from '../assets/images/realms/ellipse.png';
import HandIcon from '../assets/images/icons/click_tap_single_hand_gesture_icon.svg';
import PageContainer from "../components/PageContainer";
import {InfoPaper} from "../components/Paper";
import StyledRoundedButton from "../components/StyledRoundedButton";
import {RealmItem} from "../components/RealmItem";
import {Navigation} from "../components/Navigation";
import {RoutesMap} from "../utils/RoutesMap";
import {RealmsModal} from "../components/Modals/RealmsModal";
import {appConfig} from "../config/appConfig";
import {RealmDictionaryItem, SimplyDictionaryItem} from "../types/dictionaries";
import {RealmItemsContext} from "../App";

const RealmEllipse = styled('div')(({theme}) => ({
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: '88.6vh',
    width: 'max-content',
    '& .ellipse_main': {
        position: 'relative',
        right: 0,
        bottom: 0,
        width: 'auto',
        height: '100%'
    },
}));

const StyledMobileRealmButton = styled('button')(({theme}) => ({
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
    border: 'none',
    width: '96px',
    height: '96px',
    '& img': {
        width: '55px'
    },
    '& p': {
        marginTop: '5px'
    }
}));


const RealmsPage = (): JSX.Element => {

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const realmsData = useContext(RealmItemsContext);

    const [modalOpen, setModalOpen] = useState<number | null>(null);
    const [pageDictionary, setPageDictionary] = useState<string[] | null>(null);

    useEffect(() => {
        axios({
            method: 'get',
            url: '/realms-page',
            baseURL: appConfig.apiUrl
        })
            .then(response => {
                setPageDictionary(response.data.map((item: SimplyDictionaryItem) => item.text))
            })
    }, []);

    const handleClose = () => {
        setModalOpen(null);
    }

    const handleOpen = (realmId: number) => {
        setModalOpen(realmId);
    }

    const onClickRealm = (realmId: number) => {
        handleOpen(realmId);
    }

    const returnBack = useCallback(() => {
        navigate(RoutesMap.Cards);
    }, []);


    const redirectToExternalResource = () => {
        window.location.href = appConfig.externalResourceLink;
    }

    const InstructionsPaperComponent = (
        <Fade in timeout={appConfig.fadeAnimationDuration}>
            <Box
                sx={{
                    width: {
                        xs: '100%',
                        md: 350
                    },
                    zIndex: 100
                }}
                m={{
                    xs: 0,
                    md: 3
                }}
            >
                <InfoPaper mode="transparent">
                    <Typography variant="body2" sx={{
                        fontSize: isMobile ? 14 : '1.6vh'
                    }}>
                        {pageDictionary && pageDictionary[0]}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={{
                        md: 4
                    }} sx={{
                        fontSize: '1.6vh'
                    }}>
                        {pageDictionary && pageDictionary[1]}
                    </Typography>
                    <Grid container alignItems="start" display={{xs: 'none', md: 'flex'}}>
                        <Grid xs="auto">
                            <Box
                                sx={{
                                    width: 50
                                }}
                            >
                                <img src={HandIcon} alt="" style={{height: '5vh'}}/>
                            </Box>
                        </Grid>
                        <Grid xs>
                            <Typography variant="body2" sx={{
                                fontSize: '1.6vh'
                            }}>
                                Click each section to
                                learn about the realm
                                and the 2 relevant gifts/shadows
                            </Typography>
                        </Grid>
                    </Grid>
                </InfoPaper>
            </Box>
        </Fade>
    );

    const DiscoverMoreComponent = (
        <Fade in timeout={appConfig.fadeAnimationDuration}>
            <Box
                sx={{
                    width: '100%',
                    zIndex: 220,
                    position: 'relative'
                }}
                textAlign="center"
                mb={6}
                mt={{
                    xs: 3,
                    md: 0
                }}
            >
                <StyledRoundedButton text="discover" onClick={redirectToExternalResource}/>
            </Box>
        </Fade>
    );

    const RealmEllipseComponent = (
        <RealmEllipse>
            <img className="ellipse_main" src={Ellipse} alt=""/>
            {realmsData?.map((realm: RealmDictionaryItem) =>
                <RealmItem
                    key={realm.id}
                    onClick={() => onClickRealm(realm.id)}
                    image={realm.image}
                    title={`${realm.id}.${realm.name}`}
                    positionX={realm.positionX}
                    positionY={realm.positionY}
                />
            )}
        </RealmEllipse>
    );

    const RealmEllipseMobileComponent = (
        <Box mt={3} mb={4}>
            <Grid container>
                {realmsData?.map((realm: RealmDictionaryItem) =>
                    <Grid xs={4} key={realm.id}>
                        <Box mb={1}>
                            <StyledMobileRealmButton onClick={() => onClickRealm(realm.id)}>
                                <img src={realm.image} alt=""/>
                                <Typography variant="body2" color={theme.palette.primary.main}>
                                    {`${realm.id}.`}<span style={{textTransform: 'capitalize'}}>{realm.name}</span>
                                </Typography>
                            </StyledMobileRealmButton>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );

    return (
        <PageContainer style={{overflow: isMobile ? 'scroll' : 'hidden', position: 'fixed'}}>
            <Fade in timeout={appConfig.fadeAnimationDuration}>
                <Box sx={{
                    height: {
                        xs: 'auto',
                        md: '100%'
                    },
                    width: '100%'
                }}
                >
                    <Box
                        sx={{
                            width: {
                                xs: '100%',
                                md: 'max-content'
                            },
                            display: {
                                xs: 'block',
                                md: 'flex'
                            },
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: {
                                xs: 'auto',
                                md: '100%'
                            },
                        }}
                        mt={{
                            xs: 2,
                            md: 0
                        }}
                        px={{
                            xs: 2,
                            md: 0
                        }}
                    >
                        {isMobile &&
                            <>
                                <Grid container alignItems="start" justifyContent="center">
                                    <Box mb={{
                                        xs: 3,
                                        md: 4
                                    }}>
                                        <Navigation
                                            showBack
                                            onClickBack={returnBack}
                                        />
                                    </Box>
                                </Grid>
                                <Grid container alignItems="start" display={{xs: 'flex', md: 'none'}}>
                                    <Grid xs="auto">
                                        <Box
                                            sx={{
                                                width: 50
                                            }}
                                        >
                                            <img src={HandIcon} alt=""/>
                                        </Box>
                                    </Grid>
                                    <Grid xs>
                                        <Typography variant="body2">
                                            Click each section to
                                            learn about the realm
                                            and the 2 relevant gifts/shadows
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {RealmEllipseMobileComponent}
                            </>
                        }

                        {InstructionsPaperComponent}
                        {DiscoverMoreComponent}
                    </Box>
                </Box>
            </Fade>
            <Box sx={{
                position: 'absolute',
                zIndex: 250,
                top: theme.spacing(2),
            }} display={{xs: 'none', md: 'block'}}>
                <Navigation
                    showBack
                    onClickBack={returnBack}
                />
            </Box>

            {!isMobile && RealmEllipseComponent}

            <RealmsModal
                open={!!modalOpen}
                onClose={handleClose}
                text={modalOpen && realmsData ? realmsData[Number(modalOpen) - 1].text : []}
                name={modalOpen && realmsData ? realmsData[Number(modalOpen) - 1].name : ""}
                realmImage={modalOpen && realmsData ? realmsData[Number(modalOpen) - 1].popupImage : ""}
                number={modalOpen || 0}
            />

        </PageContainer>
    )
}

export default RealmsPage;

import React from 'react';

import {styled} from "@mui/material/styles";
import {Input, InputAdornment} from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import tz_lookup from 'tz-lookup';
import theme from "../../../theme";
import {HomepageFormData} from "../../../types/homepageForm";

interface CityAutocompleteProps {
    value: HomepageFormData["birthLocation"]["value"];
    setUserTimezone: (zone: string) => void;
    onChange: (e: string) => void;
    error: boolean;
};


const StyledSuggestionItem = styled('div')({
    cursor: 'pointer',
    padding: 10
});

const StyledSuggestionsContainer = styled('div')(({theme}) => ({
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    top: 30,
    width: '100%',
    zIndex: 109
}));

const StyledProgressContainer = styled('div')(({theme}) => ({
    padding: theme.spacing(2),
    textAlign: 'center'
}));

export const CityAutocomplete = (props: CityAutocompleteProps) => {

    const {
        value,
        onChange,
        error,
        setUserTimezone
    } = props;

    const handleChange = (address: any) => {
        onChange(address)

    };

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                getLatLng(results[0])
                    .then(results => {
                        setUserTimezone(tz_lookup(results.lat, results.lng))
                        onChange(address)
                    })


            })
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
    };

    const searchOptions = {
        types: ['locality']
    }
    // @ts-ignore
    return (
        <PlacesAutocomplete
            value={value}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={searchOptions}
        >
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                <>

                    <Input error={error} sx={{
                        input: {
                            "&::placeholder": {
                                opacity: 1,
                            },
                        }
                    }} {...getInputProps({
                        className: 'location-search-input',
                        placeholder: 'Birth Location'
                    }) as any} startAdornment={
                        <InputAdornment position="start">
                            <PlaceOutlinedIcon color="primary"/>
                        </InputAdornment>
                    }/>
                    <StyledSuggestionsContainer className="autocomplete-dropdown-container">
                        {loading &&
                            <StyledProgressContainer>
                                <CircularProgress/>
                            </StyledProgressContainer>
                        }
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText
                                }
                                : {};
                            return (
                                <StyledSuggestionItem
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </StyledSuggestionItem>
                            );
                        })}
                    </StyledSuggestionsContainer>
                </>
            )}
        </PlacesAutocomplete>
    );
}

import React, {ReactNode, useEffect, useRef} from "react";
import Grain from "../utils/CanvasGrain";
import Box from "@mui/material/Box";


interface PageContainerProps {
    children: ReactNode,
    background?: string,
    style?: Record<string, string>,
    verticalAlign?: "center" | "start" | "end" | "space-between" | "space-around"
};

const PageContainer = (props: PageContainerProps): JSX.Element => {

    const {children, background, style, verticalAlign = "center"} = props;

    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const grain = new Grain(canvasRef.current);
        }
    }, [canvasRef]);


    return (
        <div className="page_container_box" style={{  backgroundImage: background ? `url(${background})` : 'linear-gradient(40deg, rgba(90,88,105,1) 0%, rgba(77,95,113,1) 50%, rgba(90,88,105,1) 100%)', backgroundSize: 'cover', ...style}}>
            <canvas className="grain_canvas" ref={canvasRef}/>
            <Box component="div" sx={{
                alignItems: verticalAlign,
                justifyContent: "center",
                display: 'flex',
                height: {
                    xs: 'auto',
                    md: '100%'
                },
                zIndex: 10,
                position: 'relative'
            }}>
                {children}
            </Box>

        </div>
    )
}

export default PageContainer;

import React, {ReactNode} from "react";
import SendBody from '../assets/images/rounded-button/sendBody.png';
import PurchaseBody from '../assets/images/rounded-button/purchaseBody.png';
import DiscoverBody from '../assets/images/rounded-button/discoverBody.png';
import ButtonArrow from '../assets/images/rounded-button/roundedButtonArrow.png';
import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";


type ButtonText = 'send' | 'purchase' | 'discover';

interface StyledRoundedButtonProps {
    text: ButtonText,
    onClick: () => void,
};

const RoundedButton = styled(Button)(({style, theme}) => ({
    boxShadow: 'none',
    backgroundColor: 'transparent',
    width: '180px',
    height: '180px',
    backgroundImage: 'none !important',
    transition: 'transform 1.2s',
    '& .image_btn': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        transition: 'transform 2.2s',
    },
    // '&::before': {
    //     content: '""',
    //     position: 'absolute',
    //     width: '92%',
    //     height: '100%',
    //     top: 0,
    //     left: 0,
    //     backgroundImage: style?.backgroundImage,
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center center',
    //     transition: 'transform 2.2s',
    // },
    '& .arrow_btn': {
        position: 'absolute',
        width: '85px',
        right: '0px',
        marginTop: '9px',
        transition: ' all 1.2s'
    },
    '&:hover': {
        backgroundColor: 'transparent',
        '& .image_btn': {
            transform: 'rotate(55deg)',
        },
        '& .arrow_btn': {
            right: '-10px',
         },
    },
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    [theme.breakpoints.down('md')]: {
        width: '130px',
        height: '130px',
        '& .arrow_btn': {
            width: '70px',
            right: 0
        },
        '&:hover': {
            '& .image_btn': {
                transform: 'none',
            },
            '& .arrow_btn': {
                right: 0,
            },
        }
    }
}));

const StyledRoundedButton = (props: StyledRoundedButtonProps): JSX.Element => {

    const {text, onClick} = props;

    const buttonBgMap = {
        send: SendBody,
        purchase: PurchaseBody,
        discover: DiscoverBody
    } as Record<ButtonText, any>;

    return (
        <RoundedButton onClick={onClick} style={{backgroundImage: `url(${buttonBgMap[text]})`}}>
            <img className="image_btn" src={buttonBgMap[text]} alt=""/>
            <img className="arrow_btn" src={ButtonArrow} alt=""/>
        </RoundedButton>
    )
}

export default StyledRoundedButton;

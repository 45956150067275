export enum ScreenWidthPoints {
    Mobile = 'mobile',
    SM = 'small',
    FHD = 'fullhd',
    XL = 'xl',

}

export const getBackgroundSize = (screenWidth: number): ScreenWidthPoints => {

    if (screenWidth < 550) {
        return ScreenWidthPoints.Mobile
    } else if (screenWidth < 1024) {
        return ScreenWidthPoints.SM
    } else if (screenWidth < 1920) {
        return ScreenWidthPoints.FHD
    } else {
        return ScreenWidthPoints.XL
    }
};

import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom'
import './App.css';
import CardsPage from "./pages/Cards";
import HomePage from "./pages/Home";
import NotFoundPage from "./pages/NotFound";
import RealmsPage from "./pages/Realms";
import {RoutesMap} from "./utils/RoutesMap";
import axios from "axios";
import {appConfig} from "./config/appConfig";
import {CardDictionaryItem, RealmDictionaryItem} from "./types/dictionaries";
import {Backdrop, CircularProgress} from "@mui/material";
import {ErrorBoundary} from "react-error-boundary";

type BodyGraphDataType = Record<string, any>;

export const CardItemsContext = React.createContext<CardDictionaryItem[] | null>(null);
export const RealmItemsContext = React.createContext<RealmDictionaryItem[] | null>(null);

export const BodyGraphContext = React.createContext({
    data: null as BodyGraphDataType | null,
    setData: (data: BodyGraphDataType) => {
    }
})

function App() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [bodyGraph, setBodyGraph] = useState<Record<string, any> | null>(null);
    const [cardItemsDictionary, setCardItemsDictionary] = useState<CardDictionaryItem[] | null>(null);
    const [realmItemsDictionary, setRealmItemsDictionary] = useState<RealmDictionaryItem[] | null>(null);


    useEffect(() => {
        const data = localStorage.getItem('bodygraph');
        if (data) {
            const parsedData = JSON.parse(data);
            setBodyGraph(parsedData);
        }
    }, []);

    useEffect(() => {
        const cardItemsRequest = axios.get('/card-items', {
            baseURL: appConfig.apiUrl
        });
        const realmItemsRequest = axios.get('/realm-items', {
            baseURL: appConfig.apiUrl
        });
        setLoading(true);
        axios.all([cardItemsRequest, realmItemsRequest])
            .then(axios.spread(function (cardsRes, realmsRes) {
                setCardItemsDictionary(cardsRes.data);
                setRealmItemsDictionary(realmsRes.data);
            }))
            .catch(err => {
                setError(true);
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })


    }, []);


    const setBodyGraphData = (data: BodyGraphDataType) => {
        setBodyGraph(data);
        localStorage.setItem('bodygraph', JSON.stringify(data));
    }

    const ErrorFallback = ({error}: any) => (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>

        </div>
    );

    if (loading) {
        return (
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }

    if (error) {
        throw new Error('Cannot load dictionary content');
    }

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <BodyGraphContext.Provider value={{
                data: bodyGraph,
                setData: setBodyGraphData
            }}>
                <CardItemsContext.Provider value={cardItemsDictionary}>
                    <RealmItemsContext.Provider value={realmItemsDictionary}>
                        <Routes>
                            <Route index path={RoutesMap.Home} element={<HomePage/>}/>
                            <Route path={RoutesMap.Cards} element={<CardsPage/>}/>
                            <Route path={RoutesMap.Realms} element={<RealmsPage/>}/>
                            <Route path="*" element={<NotFoundPage/>}/>
                        </Routes>
                    </RealmItemsContext.Provider>
                </CardItemsContext.Provider>
            </BodyGraphContext.Provider>
        </ErrorBoundary>
    );
}

export default App;

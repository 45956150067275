import React, {useState} from 'react';
import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabGift from "../assets/images/icons/tab-gift.svg";
import TabShadow from "../assets/images/icons/tab-shadow.svg";
import Box from "@mui/material/Box";
import TabContext from '@mui/lab/TabContext';
import {TabPanel} from "@mui/lab";
import {Typography} from "@mui/material";
import {CardDictionaryItem} from "../types/dictionaries";


interface CardDescriptionTabsProps {
    card: CardDictionaryItem
}

const AntTabs = styled(Tabs)(({theme}) => ({
    minHeight: 0,
    '& .MuiTabs-indicator': {
        display: 'none'
    },
}));

const AntTab = styled(Tab)(
    ({theme}) => ({
        minWidth: 0,
        minHeight: 0,
        padding: theme.spacing(2),
        margin: theme.spacing(-2, 0, 0, -2),
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        opacity: .5,
        transition: 'opacity .3s',
        '&:hover': {
            opacity: 1,
        },
        '&.Mui-selected': {
            opacity: 1,
        },
    }),
);

const StyledTabPanel = styled(TabPanel)(
    ({theme}) => ({
        padding: theme.spacing(0),
        '.MuiTabPanel-root': {
            padding: theme.spacing(0)
        }

    }),
);


export const CardDescriptionTabs = (props: CardDescriptionTabsProps) => {
    const {
        card
    } = props;

    const [value, setValue] = useState<string>("1");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    return (
        <TabContext value={value}>
            <Box>
                <AntTabs value={value} onChange={handleChange} aria-label="card description tabs">
                    <AntTab value="1" label={<img src={TabGift} alt=""/>} aria-label="Gift"></AntTab>
                    <AntTab value="2" label={<img src={TabShadow} alt=""/>} aria-label="Shadow"></AntTab>
                </AntTabs>
                <Box>
                    <StyledTabPanel value="1">
                        <Typography variant="overline" display="block" mb={2}>
                            {card.gift.title}
                        </Typography>
                        <Typography variant="caption" display="block" mb={2}>
                            {card.gift.text}
                        </Typography>
                        <Typography variant="caption" display="block" >
                            {`Themes: ${card.themes}`}
                        </Typography>
                    </StyledTabPanel>
                    <StyledTabPanel value="2">
                        <Typography variant="overline" display="block" mb={2}>
                            {card.shadow.title}
                        </Typography>
                        <Typography variant="caption" display="block" mb={2}>
                            {card.shadow.text}
                        </Typography>
                        <Typography variant="caption" display="block" >
                            {`Themes: ${card.themes}`}
                        </Typography>
                    </StyledTabPanel>
                </Box>
            </Box>
        </TabContext>
    );
}

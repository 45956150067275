import React, {useCallback, useState} from 'react';
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import cn from 'classnames';

interface GiftCardProps {
    bgImage: string,
    onClick: () => void,
    mode: 'selected' | 'unselected',
    selected?: boolean,
    index: number,
    cardIndex: number,

    small?: boolean,
}

const StyledCard = styled(Box)(({theme, style}) => ({
    position: 'relative',
    width: '216px',
    height: '360px',
    backgroundImage: style?.backgroundImage,
    backgroundColor: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    borderRadius: '6px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    transitionProperty: 'transform, filter',
    transitionDuration: '.4s',
    transitionTimingFunction: 'ease-out',
    '&.small': {
        width: '125px',
        height: '208px',
    },
    '&.selectedMode': {
        minWidth: '266px',
        height: '433px',
        filter: 'brightness(35%)',
        position: 'absolute',
        right: 0,
        transition: 'left 1s ease',
        '&.small': {
            minWidth: '125px',
            width: '125px',
            height: '208px',
        },

        [theme.breakpoints.down('md')]: {
            minWidth: '186px',
            width: '186px',
            height: '310px',
            '&.small': {
                minWidth: '186px',
                width: '186px',
                height: '310px',
            }
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '167px',
            width: '167px',
            height: '279px',
            '&.small': {
                minWidth: '167px',
                width: '167px',
                height: '279px',
            }
        }
    },
    '&.selected': {
        filter: 'none',
        zIndex: 110,
    }
}));

export const GiftCard = (props: GiftCardProps) => {
    const {
        bgImage,
        onClick,
        mode,
        selected,
        index,
        cardIndex,
        small = false
    } = props;

    const [right, setRight] = useState(0);

    const measuredRef = useCallback((node: HTMLDivElement) => {
        if (node !== null) {
            const cardWidth = node?.clientWidth;
            const cardParent = node?.parentElement;
            const cardParentChildrenCount = cardParent?.children.length || 0;
            const cardParentWidth = cardParent?.clientWidth || 0;
            if (cardParent) {
                cardParent.style.height = cardParent?.children[0].clientHeight + 'px';
            }
            setRight((cardIndex) * ((cardParentWidth - cardWidth) / (cardParentChildrenCount - 1)))
        }
    }, [mode]);


    return (
        <StyledCard
            ref={measuredRef}
            component="div"
            sx={{
                zIndex: 100 - Math.abs(cardIndex - index)
            }}
            className={cn({
                "selectedMode": mode === "selected",
                "selected": selected,
                "small": small
            })}
            style={{
                backgroundImage: `url(${bgImage})`,
                left: mode === "selected" ? `${right}px` : 0,
                position: mode === "selected" ? 'absolute' : 'relative'
            }}
            onClick={onClick}
        />
    );
}

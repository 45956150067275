import React from 'react';
import {styled} from "@mui/material/styles";

interface RealmItemProps {
    positionX: number;
    positionY: number;
    title: string;
    image: string;
    onClick: () => void;
};

const RealmStyled = styled('div')(({}) => ({
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: '5vh',
    width: '6vw',
    fontSize: '1.5vh',
    cursor: 'pointer',
    textAlign: 'center',
    '& span': {
        textTransform: 'capitalize',
        fontWeight: 300,
    }
}));

export const RealmItem = (props: RealmItemProps) => {

    const {
        positionX,
        positionY,
        title,
        image,
        onClick
    } = props;

    return (
        <RealmStyled
            onClick={onClick}
            style={{
                right: `${positionX}%`,
                bottom: `${positionY}%`
            }}
        >
            <img src={image} alt={title}/>
            <span>{title}</span>
        </RealmStyled>
    );
}

import React from 'react';
import {styled} from "@mui/material/styles";
import {Modal, ModalProps} from "@mui/material";


interface ModalContainerProps extends ModalProps {
    children: React.ReactElement
};

const StyledModal = styled(Modal)(() => ({
    '& .MuiBackdrop-root': {
        background: 'rgba(0,0,0,.8)',
        backdropFilter: 'blur(5px)'
    }
}));

export const ModalContainer = (props: ModalContainerProps) => {
    const {
        children
    } = props;

    return(
        <StyledModal {...props}>
            {children}
        </StyledModal>
    );
}

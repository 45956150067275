import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Fade, Typography, useMediaQuery } from '@mui/material';

import BackgroundXL from '../assets/images/backgrounds/home-page-bg-xl.webp';
import BackgroundFHD from '../assets/images/backgrounds/home-page-bg-fhd-min.webp';
import BackgroundSM from '../assets/images/backgrounds/home-page-bg-small-min.webp';
import BackgroundMobile from '../assets/images/backgrounds/home-page-bg-mobile-min.webp';
import Logo1 from '../assets/images/logo/RoBTypeLogo-White-1.png';
import Logo2 from '../assets/images/logo/RoBTypeLogo-White-2.png';
import RoBStackedLogo from '../assets/images/logo/RoBOvalLogo.png';
import PageContainer from '../components/PageContainer';
import HomepageForm from '../components/Forms/HomepageForm';
import StyledRoundedButton from '../components/StyledRoundedButton';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { HomepageFormData, SetFieldCallback } from '../types/homepageForm';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { appConfig } from '../config/appConfig';
import { BodyGraphContext } from '../App';
import { RoutesMap } from '../utils/RoutesMap';
import CircularProgress from '@mui/material/CircularProgress';
import { SimplyDictionaryItem } from '../types/dictionaries';
import { getBackgroundSize, ScreenWidthPoints } from '../utils/getBgSize';

const HomePageStyledContainer = styled(Box)(({ theme }) => ({
  marginLeft: '5%',
  marginRight: '9%',
  [theme.breakpoints.down('md')]: {
    marginLeft: '25px',
    marginRight: '25px'
  }
}));

const HomePageLogo = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '44%',
  width: '98px',
  height: '117px',
  backgroundImage: `url(${RoBStackedLogo})`,
  backgroundSize: 'contain',
  [theme.breakpoints.down('md')]: {
    position: 'static',
    width: '60px',
    height: '72px',
    top: '15px',
    left: 0,
    right: 0,
    margin: 'auto'
  }
}));

const StyledLogoImage = styled('img')({
  width: '100%'
});

const bgWidthPoint = getBackgroundSize(window.screen.width);

const bgMap = {
  [ScreenWidthPoints.Mobile]: BackgroundMobile,
  [ScreenWidthPoints.SM]: BackgroundSM,
  [ScreenWidthPoints.FHD]: BackgroundFHD,
  [ScreenWidthPoints.XL]: BackgroundXL
} as Record<ScreenWidthPoints, string>;

const HomePage = (): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const BodyGraph = useContext(BodyGraphContext);

  const [pageDictionary, setPageDictionary] = useState<string[] | null>(null);
  const [formData, setFormData] = useState<HomepageFormData>({
    name: {
      error: false,
      value: '',
      isRequired: true,
      validation: new RegExp(/^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/),
      errorText: 'Please add your Full name'
    },
    email: {
      error: false,
      value: '',
      isRequired: true,
      validation: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/g),
      errorText: 'Incorrect email'
    },
    birthDate: {
      error: false,
      value: null,
      isRequired: true,
      validation: null
    },
    birthLocation: {
      error: false,
      value: '',
      isRequired: true,
      validation: null
    },
    birthTime: {
      error: false,
      value: null,
      isRequired: true,
      validation: null
    }
  });
  const [userTimezone, setUserTimezone] = useState<string>('');
  const [isSending, setSending] = useState<boolean>(false);

  useEffect(() => {
    axios({
      method: 'get',
      url: '/home',
      baseURL: appConfig.apiUrl
    }).then(response => {
      setPageDictionary(response.data.map((item: SimplyDictionaryItem) => item.text));
    });
  }, []);

  const setField: SetFieldCallback = (name, value) => {
    const field = { ...formData[name] };
    field.value = value;
    setFormData(prevState => ({
      ...prevState,
      [name]: field
    }));
  };

  const handleClickOnSend = (formData: HomepageFormData) => {
    let isFormValid = true;

    Object.entries(formData).forEach(([key, field]) => {
      let isError = false;
      const { value, isRequired, validation } = field;

      // check required
      if (!value && isRequired) {
        isError = true;
      }

      // check fields correct
      if (validation instanceof RegExp) {
        if (value && !value.match(validation)) {
          isError = true;
        }
      }

      // check date and time
      if (value && value instanceof dayjs) {
        if (!dayjs(value as any).isValid()) {
          isError = true;
        }
      }

      // set error to state
      if (isError) {
        isFormValid = false;
      }
      setFormData(prevState => ({
        ...prevState,
        [key]: {
          ...field,
          error: isError
        }
      }));
    });

    if (isFormValid) {
      setSending(true);

      const date = dayjs(formData['birthDate'].value as Dayjs).format('YYYY-MM-DD');
      const time = dayjs(formData['birthTime'].value as Dayjs).format('HH:mm');

      axios
        .post(
          'https://api.bodygraphchart.com/v210502/hd-data',
          {},
          {
            params: {
              api_key: appConfig.BodygraphApiKey,
              date: `${date} ${time}`,
              timezone: userTimezone
            }
          }
        )
        .then(res => {
          BodyGraph.setData(res.data);
        })
        .then(() => {
          return axios.post(
            `https://api.convertkit.com/v3/forms/${appConfig.ConvertKitFormId}/subscribe`,
            {},
            {
              params: {
                api_key: appConfig.ConvertkitApiKey,
                email: formData.email.value,
                first_name: formData.name.value
              }
            }
          );
        })
        .then(() => {
          navigate(RoutesMap.Cards);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setSending(false);
        });
    }
  };

  return (
    <PageContainer background={bgMap[bgWidthPoint]}>
      <HomePageStyledContainer component='section'>
        <Grid container spacing={2} justifyContent='space-between'>
          {isMobile && (
            <Grid item xs={12}>
              <Fade in timeout={appConfig.fadeAnimationDuration}>
                <Box mt={2}>
                  <HomePageLogo component='div' />
                </Box>
              </Fade>
            </Grid>
          )}
          <Grid item xs={12} md={8} lg={6}>
            <Fade in timeout={appConfig.fadeAnimationDuration}>
              <StyledLogoImage src={Logo1} alt='' />
            </Fade>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            order={{ xs: 3, md: 0 }}
            mt={{ xs: 2, md: 0 }}
            mb={{ xs: 2, md: 0 }}>
            <Fade in timeout={appConfig.fadeAnimationDuration}>
              <Box
                mb={3}
                sx={{
                  display: {
                    xs: 'none',
                    md: 'block'
                  }
                }}>
                <Typography mb={3} variant='subtitle2' color='common.white'>
                  {pageDictionary && pageDictionary[0]}
                </Typography>
                <Typography variant='subtitle2' color='common.white'>
                  {pageDictionary && pageDictionary[1]}
                </Typography>
                <Typography variant='subtitle2' color='common.white'>
                  {pageDictionary && pageDictionary[2]}
                </Typography>
              </Box>
            </Fade>
            <Box
              sx={{
                display: {
                  xs: 'block',
                  md: 'none'
                }
              }}>
              <Typography variant='body1' mb={2} color='common.white'>
                {pageDictionary && pageDictionary[0]} {pageDictionary && pageDictionary[1]}
              </Typography>
              <Typography variant='body1' color='common.white'>
                {pageDictionary && pageDictionary[2]}
              </Typography>
            </Box>
          </Grid>
          <Grid item container justifyContent='center'>
            <Grid
              container
              item
              xs={12}
              md={8}
              lg={6}
              direction='row'
              justifyContent='center'
              alignItems='center'
              mt={{ md: 4 }}
              mb={{ md: 8 }}>
              <Fade in timeout={appConfig.fadeAnimationDuration}>
                <StyledLogoImage src={Logo2} alt='' />
              </Fade>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent='space-between'>
          <Grid item xs={5} />
          <Fade in timeout={appConfig.fadeAnimationDuration}>
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <HomepageForm
                formData={formData}
                setField={setField}
                setUserTimezone={setUserTimezone}
              />
            </Grid>
          </Fade>
          <Fade in timeout={appConfig.fadeAnimationDuration}>
            <Grid
              item
              xs={12}
              lg={2}
              xl='auto'
              sx={{
                position: 'relative'
              }}>
              <Box
                ml={4}
                mt={{ xs: -8, md: 0 }}
                sx={{
                  position: 'absolute',
                  right: 0,
                  [theme.breakpoints.up('lg')]: {
                    position: 'relative'
                  }
                }}>
                {isSending ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: {
                        xs: '130px',
                        md: '180px'
                      },
                      height: {
                        xs: '130px',
                        md: '180px'
                      }
                    }}>
                    <CircularProgress color='secondary' />
                  </Box>
                ) : (
                  <StyledRoundedButton onClick={() => handleClickOnSend(formData)} text='send' />
                )}
              </Box>
            </Grid>
          </Fade>
        </Grid>

        {!isMobile && (
          <Fade in timeout={appConfig.fadeAnimationDuration}>
            <HomePageLogo component='div' />
          </Fade>
        )}
      </HomePageStyledContainer>
    </PageContainer>
  );
};

export default HomePage;
